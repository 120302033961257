html, body {
  box-sizing: border-box;
  background: #e5e5e5;
  min-height: 100vh;
}

.white-card {
  border-radius: 16px;
  background: white;
  padding: 30px;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login-logo {
  border-radius: 16px;
  width: 50%;
}

.center-container {
  width: 60%;
  margin: 40px auto;

}

.reservation-data {
  background: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px #adadad;
  margin-top: 100px;
}

.room-data {
  background: rgba(175, 175, 175, 0.55);
  border-radius: 20px;
  margin-top: 20px;
  padding: 20px;
}

.login-card {
  width: 30%;
  border-radius: 16px;
  background: white;
  padding: 30px;
}

@media screen and (max-width: 1000px){
  .center-container {
    width: 98%;
  }

  .reservation-data {
    padding: 4px;
  }

  .room-data {
    padding: 10px;
  }

  .login-card {
    width: 80%;
  }
}


.person-data {
  margin-top: 10px;
}

.center {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}